import {useEffect, useState} from 'react';
import { Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Message = (props) => {
    const _location = useLocation();
    const [message, setMessage] = useState(undefined);

    useEffect(() => {
        if(_location.state?.message) {
            setMessage(_location.state.message);
        }
    }, [_location]);

    return (<>
        {message !== undefined && (<Alert severity={message.status} variant="filled" align="center" sx={{m:0, p:2}}>
            {message.message}
            </Alert>)}
    </>);
}

export default Message;
