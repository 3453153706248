import {useState, useEffect} from 'react';
import {Stack, Button, Box} from '@mui/material';
import Captcha from './Captcha';
import Preview from './Preview';
import Message from './Message';
import Fields from './Fields';
import {useNavigate} from 'react-router-dom';
import {post} from '../constants/api';

const Form = () =>  {
    const [loaded, setLoaded] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaUID, setCaptchaUID] = useState(null);
    const [sent, setSent] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        post(email, phone, message, captchaUID, captcha).then((data) => {
            if(data.status === 'success') {
                setSent(true);
            }
            navigate('.', {state:{message:data}});
        });
    }

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            navigate('.', {replace: true});
        }
    }, [loaded, navigate]);

    return (<Stack spacing={2}>
        <Message />
        {!sent && captcha.length !== 3 && (<Fields email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} message={message} setMessage={setMessage} />)}

        <Preview email={email} phone={phone} message={message} sent={sent} captcha={captcha} setCaptcha={setCaptcha} />

        {!sent && email.length > 0 && phone.length > 0 && message.length > 0 && (<Captcha captcha={captcha} setCaptcha={setCaptcha} setCaptchaUID={setCaptchaUID} />)}

        {!sent && email.length > 0 && phone.length > 0 && message.length > 0 && captcha.length === 3 && (<Box sx={{mt:2, mb:4}}>
            <Button fullWidth onClick={() => handleSubmit()} size="large" variant="contained">Enviar</Button>
            </Box>)}
        </Stack>
        );
}

export default Form;
