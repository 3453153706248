import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Layout from './components/Layout';
import Form from './components/Form';
import NotFound from './components/NotFound';

const App = () => {
    const routes = [
        {
            path: "/",
            element: <Layout />,
            children: [
                { path: "", element: <Form /> },
            ],
        },
        { path: "*", element: <NotFound /> },
    ];
    const router = createBrowserRouter(routes);
    return (<RouterProvider router={router} />);
}

export default App;
