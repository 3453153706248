import {endpoints} from './endpoints';

export const get = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    }
    return fetch(endpoints.captcha, options).then((response) => response.json());
}

export const post = (email, phone, message, uid, captcha) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: email, phone: phone, message: message, uid: uid, captcha: captcha})
    }
    return fetch(endpoints.form, options).then((response) => response.json());
}
