import {Paper, Stack, TextField} from '@mui/material';

const Fields = (props) => {
    const email = props.email;
    const phone = props.phone;
    const message = props.message;
    const setEmail = props.setEmail;
    const setPhone = props.setPhone;
    const setMessage = props.setMessage;

    return (<Paper elevation={2}>
        <Stack spacing={2} padding={2}>
            <TextField 
            onChange={(e) => setEmail(e.target.value)}
            value={email} label="Email" variant="outlined" />
            <TextField 
            onChange={(e) => setPhone(e.target.value)}
            value={phone} label="Teléfono" variant="outlined" />
            <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Mensaje"
            multiline
            rows={4} />
        </Stack>
        </Paper>);
}

export default Fields;
