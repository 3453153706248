import React from 'react';
import { useState, useEffect } from 'react';
import {Stack, TextField, Button} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import {get} from '../constants/api';
import Loader from './Loader';

const Captcha = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [imgSrc, setImgSrc] = useState('/1x1.gif');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoading(true);
            get().then((data) => {
                console.log(data)
                setImgSrc([data.label, data.data].join(','))
                props.setCaptchaUID(data.uid);
                setLoading(false);
            });
        }
    }, [loaded, props]);


    return (<Stack spacing={1} direction="row">
        {loading ? (<Loader />) : (<img height="55" src={imgSrc} alt="Captcha" />)}
        <TextField value={props.captcha} onChange={(e) => { props.setCaptcha(e.target.value); }} placeholder="Captcha" fullWidth />
        <Button variant="contained" onClick={() => {setLoaded(false)}}><ReplayIcon /></Button>
        </Stack>);
}

export default Captcha;
