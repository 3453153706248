import {Stack, Typography, Paper, Box, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const Form = (props) => {
    const email = props.email;
    const phone = props.phone;
    const message = props.message;
    const sent = props.sent;
    const captcha = props.captcha;

    return (<>
        {captcha.length === 3 && email.length !== 0 && phone.length !== 0 && props.message.length !== 0 && (<Paper elevation={2}>
            <Stack spacing={2} sx={{mt:4, pb:4, px:4}}>
                <Typography variant="h5" align="center">Vista previa de su mensaje</Typography>

                {email.length > 0 && (<>
                <Typography variant="h6">Email:</Typography>
                <Typography variant="body2" sx={{whiteSpace: 'noset', wordBreak: 'break-all'}}>{props.email}</Typography>
                </>)}

                {phone.length > 0 && (<>
                <Typography variant="h6">Teléfono:</Typography>
                <Typography variant="body2" sx={{whiteSpace: 'noset', wordBreak: 'break-all'}}>{props.phone}</Typography>
                </>)}

{message.length > 0 && (<>
    <Typography variant="h6">Mensaje:</Typography>
    <Typography variant="body2" sx={{whiteSpace: 'noset', wordBreak: 'break-all'}}>{props.message}</Typography>
    </>)}

{!sent && captcha.length === 3 && (<Box sx={{width:'100%'}} display="flex" justifyContent="flex-end">
    <IconButton onClick={() => props.setCaptcha('')}><EditIcon /></IconButton>
    </Box>)}

    </Stack>

    </Paper>)}
    </>);
}

export default Form;
