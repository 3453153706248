import {Container, Grid, Typography} from '@mui/material';

const NotFound = () => {
    return (<Container maxWidth="sm">
            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh'}}>
            <Typography variant="h2" align="center" sx={{my: 4}}>24x7</Typography>
            <Typography variant="h6" align="center" sx={{pt:4}}>Página no encontrada</Typography>
        </Grid>
    </Container>);
}
export default NotFound;
